import React from 'react';
import './Reservation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from "react";
import moment from "moment";
import { toFormData } from 'axios';

function Reservation() {
    const today = new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":"));
    const tomorrow = moment(new Date())
        .add(1,'days')
        .format('YYYY-MM-DDTHH:mm');
    const [toggle, setToggle] = useState(1);
    const [info, setInfo] = useState(2);
    const [clientName, setClientName] = useState("");
    const [clientNumber, setClientNumber] = useState("");
    const [dateIn, setDateIn] = useState("");
    const [dateOut, setDateOut] = useState("");
    const [personNum, setPersonNum] = useState("");
    const [carNum, setCarNum] = useState("");
    const [carRej, setCarRej] = useState("");
    const [carMake, setCarMake] = useState("");
    const [clientDestination, setClientDestination] = useState("");
    const [flightNumber, setFlightNumber] = useState("");
    const [clientMessage, setClientMessage] = useState("");
    const [clientKeys, setClientKeys] = useState(false);
    const [clientKidSeat, setClientKidSeat] = useState(false);
    const [agencyName, setAgencyName] = useState("");
    const [agencyNIP, setAgencyNIP] = useState("");
    const [agencyMail, setAgencyMail] = useState("");
    const [paymentSelect, setPaymentSelect] = useState("");
    const [voucherLink, setVoucherLink] = useState("https://parkingpyrzowice.info/phphandler/generatePdf.php");
    const selectOnChange = (e) => {
      const value = e.target.value;
      setPaymentSelect(value);
    };

    function  updateToggle(id) {
        setToggle(id);
    }
    function additionalInfo(id) {
        setInfo(id);
    }
    function validateDateTime() {
        var datetime1 = new Date(document.getElementById("reservation-form-dateIn").value);
        var datetime2 = new Date(document.getElementById("reservation-form-dateOut").value);
        let todaysDate = new Date();

        if (datetime1.getFullYear() < todaysDate.getFullYear() || datetime2.getFullYear() < todaysDate.getFullYear()) {
            alert("Proszę uzupełnić datę w formacie dd.mm.rrrr!");
            return false;
        }

        if (datetime1 < todaysDate || datetime2 < todaysDate) {
            alert("Data przyjazdu lub wyjazdu nie może być wcześniejsza niż dzisiejsza!");
            return false;
        }

        if (datetime2 <= datetime1) {
            alert("Data powrotu nie może być mniejsza, niż data wjazdu na parking!");
        } else {
            return true;
        }
    }
    function isInputCorrect() {
        if(clientName.length === 0){
            alert("Proszę uzupełnić imię i nazwisko.");
        }else if(clientNumber.length === 0){
            alert("Proszę uzupełnić numer telefonu.");
        }else if(dateIn === ''){
            alert("Proszę uzupełnić datę przyjazdu.");
        }else if(dateOut === ''){
            alert("Proszę uzupełnić datę wyjazdu.");
        }else if(personNum === 0){
            alert("Proszę uzupełnić liczbę osób.");
        }else if(carNum === 0){
            alert("Proszę uzupełnić liczbę pojazdów.");
        }else if(paymentSelect.length === 0){
            alert("Proszę uzupełnić formę płatności.");
        }else if(toggle === 2 && agencyNIP.length === 0){
            alert("Proszę uzupełnić NIP biura podróży.");
        }else if(toggle === 2 && agencyName.length === 0){
            alert("Proszę uzupełnić nazwę biura podróży.");
        }else if(toggle === 2 && agencyMail.length === 0){
            alert("Proszę uzupełnić adres e-mail biura podróży.");
        }else if (validateDateTime()){
            return true;
        }
    }
    async function sendSubmit(url){
            let postData = {
                'clientName': clientName,
                'clientNumber': clientNumber,
                'dateIn': dateIn,
                'dateOut': dateOut,
                'personNum': personNum,
                'carNum': carNum,
                'carRej': carRej,
                'carMake': carMake,
                'clientDestination': clientDestination,
                'flightNumber': flightNumber,
                'clientMessage': clientMessage,
                'clientKeys': clientKeys,
                'clientKidSeat': clientKidSeat,
                'agencyName': agencyName,
                'agencyNIP': agencyNIP,
                'agencyMail': agencyMail,
                'paymentMethod': paymentSelect,
            };
            await fetch(url, {
                method: "POST",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postData)
            }).then(res => {
                console.log(res);
                return res.json();
            })
            .then(data => {
                document.getElementById('reservation-form').style.display = 'none';
                document.getElementById('reservation-form-agency').style.display = 'none';
                document.getElementById('reservation-wrapper-client').style.display = 'none';
                document.getElementById('reservation-wrapper-agency').style.display = 'none';
                if (toggle === 1 ) {
                    document.getElementById("business").addEventListener('click', function(e) {
                        e.stopPropagation();
                      })
                }else {
                    document.getElementById("individual").addEventListener('click', function(e) {
                        e.stopPropagation();
                      })
                }
                document.getElementById('reservation-response').style.display = 'block';
                document.getElementById('response-title').innerHTML = data.Succes;
                document.getElementById('reservation-response').scrollIntoView();
                let link = voucherLink + "?id=" + data.id_zlecenia;
                setVoucherLink(link);
            })
            .catch(error => console.log(error))
    }

    const handleSubmit = () => {
        if(isInputCorrect()) {
            const url = 'https://parkingpyrzowice.info/phphandler/handleForm.php';
            sendSubmit(url);
        }
    }

  return (
      <div className='reservation' id="reservation">
        <div className='komunikat2'>
            <p>Obecnie trwają prace serwisowe. </p>
            <p>Prosimy o składanie rezerwacji przez telefon. </p>
            <p>Przepraszamy za niedogodności. </p>
        </div>
          <h2>REZERWACJA</h2>
          <div className='reservation-tabs-wrapper'>
              <div className={toggle === 1 ? "reservation-tab reservation-active" : "reservation-tab"} id="individual"
                   onClick={() => updateToggle(1)}>Dla klienta indywidualnego
              </div>
              <div className={toggle === 2 ? "reservation-tab reservation-active" : "reservation-tab"} id="business"
                   onClick={() => updateToggle(2)}>Dla biur podróży
              </div>
          </div>
          <div id="reservation-response">
              <div id="response-title"></div>
              <div id="response-description">Dziękujemy za skorzystanie z naszego serwisu</div>
              <div id="response-buttons">
                  <a id="voucher-link" className={toggle === 2 ? "voucher-active" : "voucher-disabled"} href={voucherLink}>
                      <div id="download-voucher" className="response-button">Pobierz voucher</div>
                  </a>
                  <div id="refresh-reservation" className="response-button"
                       onClick={() => window.location.reload()}>Kolejna rezerwacja
                  </div>
              </div>
          </div>
          <div id="reservation-wrapper-client" className={toggle === 1 ? "reservation-form-wrapper" : "reservation-form-wrapper-disabled"}>
              <form id="reservation-form">
                  <div className="reservation-form-row-alt">
                      <h3>Dane podróżnego</h3>
                  </div>
                  <div className="reservation-form-inputs">
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Imię
                                  i nazwisko: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="clientName"
                                     type="text" maxLength="35" value={clientName}
                                     onChange={(e) => setClientName(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-tel" className="reservation-form-text-input-label">Numer
                                  telefonu podróżnego: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-tel" name="clientTel"
                                     type="text" maxLength="35" value={clientNumber}
                                     onChange={(e) => setClientNumber(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-dateIn" className="reservation-form-text-input-label">Data
                                  i
                                  godzina przyjazdu: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-dateIn" name="dateIn"
                                     type="datetime-local" value={dateIn} min={today} onChange={(e) => setDateIn(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-dateOut" className="reservation-form-text-input-label">Data
                                  i
                                  godzina wyjazdu: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-dateOut"
                                     name="dateOut"
                                     type="datetime-local" value={dateOut}
                                     min={tomorrow}
                                     onChange={(e) => setDateOut(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row-alt">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-person" className="reservation-form-text-input-label">Liczba
                                  osób: *</label>
                              <input className="reservation-form-text-input-alt" id="reservation-form-person"
                                     name="personNumber"
                                     type="number" min="1" max="20" value={personNum}
                                     onChange={(e) => setPersonNum(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-vehicle" className="reservation-form-text-input-label">Liczba
                                  pojazdów: *</label>
                              <input className="reservation-form-text-input-alt" id="reservation-form-vehicle"
                                     name="vehicleNumber" type="number" min="1" max="20" value={carNum}
                                     onChange={(e) => setCarNum(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label"><label htmlFor="reservation-form-tel"
                                                                          className="reservation-form-text-input-label">Forma
                              płatności: </label>
                              <select onChange={selectOnChange} className="reservation-form-select-payment">
                                  <option value="" selected disabled hidden>Wybierz formę płatności...</option>
                                  <option value="Klient: Gotówka">Klient: Gotówka</option>
                                  <option value="Klient: Przelew">Klient: Przelew</option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div className="reservation-form-row-alt">
                      <p className="reservation-form-info-text" onClick={() => additionalInfo(1)}>
                          <FontAwesomeIcon icon='fa-solid fa-chevron-down'/> informacje dodatkowe (podaj teraz lub na
                          miejscu)
                      </p>
                  </div>
                  <div className={info === 1 ? "reservation-additional-info" : "reservation-additional-info-disabled"}>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Numer
                                  rejestracyjny pojazdu: </label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="clientDestination"
                                     type="text" maxLength="35" value={carRej}
                                     onChange={(e) => setCarRej(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-tel" className="reservation-form-text-input-label">Marka
                                  pojazdu: </label>
                              <input className="reservation-form-text-input" id="reservation-form-tel"
                                     name="vehicleMake"
                                     type="text" maxLength="35" value={carMake}
                                     onChange={(e) => setCarMake(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Przylot
                                  z: </label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="clientDestination"
                                     type="text" maxLength="35" placeholder="np. Londyn-Luton" value={clientDestination}
                                     onChange={(e) => setClientDestination(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Numer
                                  lotu powrotnego: </label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="clientFlightNum"
                                     type="text" maxLength="35" value={flightNumber}
                                     onChange={(e) => setFlightNumber(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label"><label htmlFor="reservation-form-tel"
                                                                          className="reservation-form-text-input-label">Wiadomość: </label>
                              <input className="reservation-form-text-input" id="reservation-form-tel"
                                     name="clientMessage"
                                     type="text" maxLength="100" value={clientMessage}
                                     onChange={(e) => setClientMessage(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Kod
                                  rabatowy: </label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="clientPromotionCode"
                                     type="text" maxLength="35"/>
                          </div>
                          <div className="reservation-form-row checkbox-flex-column">
                              <div className="reservation-double-checkbox">
                                  <input type="checkbox" id="reservation-form-car-keys" name="clientKeys"
                                         checked={clientKeys} onChange={(e) => setClientKeys(e.target.checked)}/>
                                  <label htmlFor="reservation-form-car-keys"
                                         className="reservation-form-accept-label">Przechowanie kluczyków
                                      (+10zł) </label>
                              </div>
                              <div className="reservation-double-checkbox">
                                  <input type="checkbox" id="reservation-form-kids-seat" name="clinetKidSeat"
                                         checked={clientKidSeat} onChange={(e) => setClientKidSeat(e.target.checked)}/>
                                  <label htmlFor="reservation-form-kids-seat"
                                         className="reservation-form-accept-label">Fotelik dla dziecka </label>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="reservation-form-row-alt reservation-form-row-regulamin">
                      <input type="checkbox" id="reservation-form-accept" required/>
                      <label htmlFor="reservation-form-accept" className="reservation-form-accept-label">Akceptuję <a
                          href="regulamin.html" target="_blank">regulamin*</a></label>
                  </div>
                  <div className="reservation-form-row">
                      <input type="button" className="reservation-form-submit" name="submit" value="Rezerwuj"
                             onClick={handleSubmit}/>
                  </div>
              </form>
          </div>
          <div id="reservation-wrapper-agency" className={toggle === 2 ? "reservation-form-wrapper" : "reservation-form-wrapper-disabled"}>
              <form id="reservation-form-agency">
                  <div className="reservation-form-row-alt">
                      <h3>Dane biura podróży</h3>
                  </div>
                  <div className="reservation-form-inputs">
                      <div className="reservation-form-row">
                          <div className="reservation-input-label-full">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Nazwa
                                  biura podróży: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="agencyName"
                                     type="text" maxLength="55" value={agencyName}
                                     onChange={(e) => setAgencyName(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label-full">
                              <label htmlFor="reservation-form-dateIn" className="reservation-form-text-input-label">NIP
                                  biura podróży: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-name" name="agencyNIP"
                                     type="text" maxLength="55" value={agencyNIP}
                                     onChange={(e) => setAgencyNIP(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label-full">
                              <label htmlFor="reservation-form-dateIn" className="reservation-form-text-input-label">E-mail
                                  do faktur: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="agencyMail"
                                     type="text" maxLength="55" value={agencyMail}
                                     onChange={(e) => setAgencyMail(e.target.value)}/>
                          </div>
                      </div>
                  </div>
                  <div className="reservation-form-row-alt">
                      <h3>Dane podróżnego</h3>
                  </div>
                  <div className="reservation-form-inputs">
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Imię
                                  i
                                  nazwisko: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="clientName"
                                     type="text" maxLength="35" value={clientName}
                                     onChange={(e) => setClientName(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-tel" className="reservation-form-text-input-label">Numer
                                  telefonu podróżnego: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-tel" name="clientTel"
                                     type="tel" maxLength="35" value={clientNumber}
                                     onChange={(e) => setClientNumber(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-dateIn" className="reservation-form-text-input-label">Data
                                  i
                                  godzina przyjazdu: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-dateIn" name="dateIn"
                                     type="datetime-local" value={dateIn} min={today} onChange={(e) => setDateIn(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-dateOut" className="reservation-form-text-input-label">Data
                                  i
                                  godzina wyjazdu: *</label>
                              <input className="reservation-form-text-input" id="reservation-form-dateOut"
                                     name="dateOut"
                                     type="datetime-local" value={dateOut}
                                     min={tomorrow}
                                     onChange={(e) => setDateOut(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-form-row-alt">
                              <div className="reservation-input-label">
                                  <label htmlFor="reservation-form-person"
                                         className="reservation-form-text-input-label">Liczba
                                      osób: *</label>
                                  <input className="reservation-form-text-input" id="reservation-form-person"
                                         name="personNumber"
                                         type="number" min="1" max="99" value={personNum}
                                         onChange={(e) => setPersonNum(e.target.value)}/>
                              </div>
                              <div className="reservation-input-label">
                                  <label htmlFor="reservation-form-vehicle"
                                         className="reservation-form-text-input-label">Liczba
                                      pojazdów: *</label>
                                  <input className="reservation-form-text-input" id="reservation-form-vehicle"
                                         name="vehicleNumber" type="number" min="1" max="20" value={carNum}
                                         onChange={(e) => setCarNum(e.target.value)}/>
                              </div>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Nr
                                  rej.
                                  pojazdu: </label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="vehicleRej"
                                     type="text" maxLength="35" value={carRej}
                                     onChange={(e) => setCarRej(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Przylot
                                  z: </label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="clientDestination"
                                     type="text" maxLength="35" placeholder="np. Londyn-Luton" value={clientDestination}
                                     onChange={(e) => setClientDestination(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-tel" className="reservation-form-text-input-label">Marka
                                  pojazdu: </label>
                              <input className="reservation-form-text-input" id="reservation-form-tel"
                                     name="vehicleMake"
                                     type="text" maxLength="35" value={carMake}
                                     onChange={(e) => setCarMake(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Numer
                                  lotu powrotnego: </label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="clientFlightNum"
                                     type="text" maxLength="35" value={flightNumber}
                                     onChange={(e) => setFlightNumber(e.target.value)}/>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-tel" className="reservation-form-text-input-label">Forma
                                  płatności: </label>
                              <select onChange={selectOnChange} className="reservation-form-select-payment">
                                  <option value="" selected disabled hidden>Wybierz formę płatności...</option>
                                  <option value="Płatność Biuro">Płatność Biuro</option>
                                  <option value="Klient: Gotówka">Klient: Gotówka</option>
                                  <option value="Klient: Przelew">Klient: Przelew</option>
                              </select>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-name" className="reservation-form-text-input-label">Kod
                                  rabatowy: </label>
                              <input className="reservation-form-text-input" id="reservation-form-name"
                                     name="clientPromotionCode"
                                     type="text" maxLength="35"/>
                          </div>
                          <div className="reservation-input-label">
                              <label htmlFor="reservation-form-tel"
                                     className="reservation-form-text-input-label">Wiadomość: </label>
                              <input className="reservation-form-text-input" id="reservation-form-tel"
                                     name="clientMessage"
                                     type="text" maxLength="100" value={clientMessage}
                                     onChange={(e) => setClientMessage(e.target.value)}/>
                          </div>
                      </div>
                      <div className="reservation-form-row-alt">
                          <div className="reservation-double-checkbox">
                              <input type="checkbox" id="reservation-form-car-keys" name="clientKeys" checked={clientKeys}
                                     onChange={(e) => setClientKeys(e.target.checked)}/>
                              <label htmlFor="reservation-form-car-keys"
                                     className="reservation-form-check-label">Przechowanie kluczyków (+10zł) </label>
                          </div>
                          <div className="reservation-double-checkbox">
                              <input type="checkbox" id="reservation-form-kids-seat" name="clinetKidSeat"
                                     checked={clientKidSeat} onChange={(e) => setClientKidSeat(e.target.checked)}/>
                              <label htmlFor="reservation-form-kids-seat"
                                     className="reservation-form-check-label">Fotelik dla dziecka </label>
                          </div>
                      </div>
                      <div className="reservation-form-row-alt accept-row">
                          <div className="reservation-double-checkbox">
                              <input type="checkbox" id="reservation-form-accept-agency" required/>
                              <label htmlFor="reservation-form-accept-agency"
                                     className="reservation-form-accept-label">Akceptuję <a
                                  href="regulamin.html" target="_blank">regulamin*</a></label>
                          </div>
                      </div>
                      <div className="reservation-form-row">
                          <input type="button" className="reservation-form-submit" name="submit-biuro" value="Rezerwuj"
                                 onClick={handleSubmit}/>
                      </div>
                  </div>
              </form>
              <div className='download-empty-voucher'><a href='/voucher_empt_fillout.pdf' download>W razie awarii pobierz voucher do wypełnienia</a></div>
          </div>

      </div>
  );
}

export default Reservation;
