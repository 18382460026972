import React from 'react';
import '../../App.css';
import Reservation from '../Reservation';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Prices from "../Prices";
import AboutUs from "../AboutUs";

function Home() {
  return (
    <>
        <HeroSection />
        <Reservation />
        <Prices />
        <AboutUs />
        <Footer />
    </>
  );
}

export default Home;
