import React, { useState, useEffect } from 'react';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  function testScroll(ev){
    if(window.pageYOffset>200) {
      document.getElementById("navbar-logo").style.opacity = '0';
    } else {
      document.getElementById("navbar-logo").style.opacity = '1';
    }
  }
  window.onscroll=testScroll
  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <a href='/' id="navbar-logo" className='navbar-logo' onClick={closeMobileMenu}>
          </a>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <a href='#aboutus-section' className='nav-links' onClick={closeMobileMenu}>
                O nas
              </a>
            </li>
            <li className='nav-item'>
              <a
                  href='#prices-section'
                  className='nav-links'
                  onClick={closeMobileMenu}
              >
                Cennik
              </a>
            </li>
            <li className='nav-item'>
              <a
                  href='#localization'
                  className='nav-links'
                  onClick={closeMobileMenu}
              >
                Lokalizacja
              </a>
            </li>
            <li className='nav-item'>
              <a
                  href='#contact'
                  className='nav-links'
                  onClick={closeMobileMenu}
              >
                Kontakt
              </a>
            </li>
            <li className='nav-item'>
              <a
                  href='regulamin.html'
                  target='_blank'
                  className='nav-links'
                  onClick={closeMobileMenu}
              >
                Regulaminy
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
