import React from 'react';
import './AboutUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tilePhoto from "../images/tilephoto1.jpeg";
import tilePhoto2 from "../images/tilephoto2.png";
import tilePhoto3 from "../images/parking-path-logo.png";
import tilePhoto4 from "../images/24-7.png";

function AboutUs() {
    return (
        <div>
            <div className="wavestop">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#B7DDFD"
                        d="M0,96L48,80C96,64,192,32,288,21.3C384,11,480,21,576,42.7C672,64,768,96,864,112C960,128,1056,128,1152,112C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
            </div>
            <div className="aboutus-section-wrapper" id="aboutus-section">
                <div className="aboutus-title"><h3>O NAS</h3></div>
                <div className="aboutus-description">
                    Posiadamy wygodne, klimatyzowane samochody, którymi bezpiecznie zawieziemy i odbierzemy Państwa wraz
                    z
                    bagażami z lotniska.
                    Czas dojazdu z parkingu ‘66’ do portu lotniczego Katowice - Pyrzowice wynosi 3-5 minut. Nasz parking
                    posiada miejsca dla
                    osób niepełnosprawnych. Na życzenie klienta wystawiamy fakturę VAT.
                </div>
                <div className="aboutus-tiles-wrapper">
                    <div className="aboutus-tiles">
                        <div className="aboutus-tile-1 aboutus-tile_first-section">
                            <img className="aboutus-tile-photo" src={tilePhoto3} alt="ParkingPath"/>
                            <div className="aboutus-tile-text">bezpłatny transfer w dwie strony</div>
                        </div>
                        <div className="aboutus-tile-2 aboutus-tile_first-section">
                            <FontAwesomeIcon icon="fa-solid fa-key"/>
                            <div className="aboutus-tile-text">możliwość przechowania kluczyków</div>
                        </div>
                        <div className="aboutus-tile-3 aboutus-tile_first-section">
                        </div>
                        <div className="aboutus-tile-4 aboutus-tile_first-section">
                            <FontAwesomeIcon icon="fa-solid fa-snowflake"/>
                            <div className="aboutus-tile-text">busy klimatyzowane</div>
                        </div>
                        <div className="aboutus-tile-5 aboutus-tile_first-section">
                            <FontAwesomeIcon icon="fa-solid fa-baby"/>
                            <div className="aboutus-tile-text">fotelik dla dziecka</div>
                        </div>
                        <div className="aboutus-tile-6 aboutus-tile_first-section">
                            <FontAwesomeIcon icon="fa-solid fa-wheelchair"/>
                            <div className="aboutus-tile-text">miejsca dla niepełnosprawnych</div>
                        </div>
                        <div className="aboutus-tile-7 aboutus-tile_first-section">
                            <FontAwesomeIcon icon="fa-solid fa-money-bill-1"/>
                            <div className="aboutus-tile-text">płatność gotówką</div>
                        </div>
                        <div className="aboutus-tile-8 aboutus-tile_first-section">
                            <div className="aboutus-tile-big">3-5 min</div>
                            <div className="aboutus-tile-text">do lotniska</div>
                        </div>
                        <div className="aboutus-tile-9 aboutus-tile_first-section">
                            <FontAwesomeIcon icon="fa-solid fa-file-invoice"/>
                            <div className="aboutus-tile-text">wystawiamy faktury VAT</div>
                        </div>
                    </div>
                </div>
                <div className="aboutus-title"><h3>BEZPIECZEŃSTWO</h3></div>
                <div className="aboutus-description">
                    U nas Twój samochód będzie bezpieczny, a Ty będziesz mógł spokojnie cieszyć się swoim urlopem.
                    Nasz parking jest dozorowany 24/7, monitorowany, oświetlony, a także ogrodzony
                </div>
                <div className="aboutus-tiles-wrapper">
                    <div className="aboutus-tiles-second">
                        <div className="aboutus-tile_first-section aboutus-tile-second1">
                            <FontAwesomeIcon icon='fa-solid fa-video'/>
                            <div className="aboutus-tile-text">monitoring</div>
                        </div>
                        <div className="aboutus-tile_first-section aboutus-tile-second2">
                            <FontAwesomeIcon icon="fa-solid fa-lightbulb"/>
                            <div className="aboutus-tile-text">oświetlenie całego parkingu</div>
                        </div>
                        <div className="aboutus-tile_first-section aboutus-tile-second3">
                            <img className="aboutus-tile-photo" src={tilePhoto4} alt="Parking24/7"/>
                        </div>
                        <div className="aboutus-tile_first-section aboutus-tile-second4">
                            <FontAwesomeIcon icon='fa-solid fa-shield-halved'/>
                            <div className="aboutus-tile-text">stały dozór</div>
                        </div>
                        <div className="aboutus-tile_first-section aboutus-tile-second5">
                            <FontAwesomeIcon icon="fa-solid fa-lock"/>
                            <div className="aboutus-tile-text">obiekt całkowicie ogrodzony</div>
                        </div>
                        <div className="aboutus-tile_first-section aboutus-tile-second6"></div>
                    </div>
                </div>
            </div>
            <div className="wavesbottom">
                <svg viewBox="0 0 500 200">
                    <path fill="#B7DDFD" d="M 0,50 C 150,100 350,0 500,50 L 500,00 L 0,0"></path>
                </svg>
            </div>
        </div>
    );
}

export default AboutUs;
