import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import backgroundImage from "../images/Rectangle87.png";
import backgroundImage2 from "../images/Rectangle3footer.png";
import partner1 from "../images/partner1.png";
import partner2 from "../images/Rectangle96.png";

function Footer() {
  return (
      <div className='footer-container'>
          <div className="footer-tiles-section">
          <div className="footer-tiles-row">
              <div className="footer-tiles-row-title" id="localization">
                <h4>LOKALIZACJA</h4>
              </div>
              <div className="footer-tiles-row-tiles">
                    <div className="footer-tiles-row-tile">
                        <div className="footer-tiles-row-icon icon-red">
                            <FontAwesomeIcon icon="fa-solid fa-map-location-dot" />
                        </div>
                        <p>Pyrzowice 42-625 <br />ul. Transportowa 7</p>
                    </div>
              </div>
          </div>
          <div className="footer-tiles-row">
              <div className="footer-tiles-row-title" id="contact">
                <h4>KONTAKT</h4>
              </div>
              <div className="footer-tiles-row-tiles">
                  <div className="footer-tiles-row-tile">
                      <div className="footer-tiles-row-icon icon-lightblue">
                        <FontAwesomeIcon icon="fa-solid fa-mobile-screen-button" />
                      </div>
                      <p>+48 666 605 838 <br/>+48 530 900 076 <br/>+48 666 605 538</p>
                  </div>
                  <div className="footer-tiles-row-tile">
                      <div className="footer-tiles-row-icon icon-blue">
                        <FontAwesomeIcon icon="fa-solid fa-envelope" />
                      </div>
                      <p>kontakt@parkingpyrzowice.info</p>
                  </div>
              </div>
          </div>
          </div>
          <div className="footer-sponsors-row">
              <img className="sporsor1-img" src={partner1} alt="Sponsors logo" />
              <img className="sporsor2-img" src={partner2} alt="Sponsors logo" />
          </div>
          <div className="footer-bottom">
              <div className="social"><a href="https://www.instagram.com/parking__66/"><FontAwesomeIcon
                  icon="fa-brands fa-instagram"/> @parking__66</a></div>
              <div className="copyright">
                  @Copyright 2024 by Parking 66. All rights reserved.
              </div>
          </div>
      </div>
  )
      ;
}

export default Footer;
