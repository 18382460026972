import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Navbar from './components/Navbar';

library.add(fab, fas)
function App() {
  return (
    <>
      <Router basename="/index.html">
        <Navbar />
        <Switch>
          <Route path='/' component={Home} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
