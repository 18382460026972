import React from 'react';
import './Prices.css';
import {useState} from 'react'

function Prices() {
    const [priceToggle, setPriceToggle] = useState(1);
    return (
        <div>
            <div className="wavestop-prices">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#D8ECFF"
                          d="M0,96L48,80C96,64,192,32,288,21.3C384,11,480,21,576,42.7C672,64,768,96,864,112C960,128,1056,128,1152,112C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
            </div>
            <div className="prices-wrapper" id="prices-section">
                <div className="prices-title"><h3>CENNIK</h3></div>
                <div className="prices-tabs-wrapper">
                    <div className={priceToggle === 1 ? "price-tab price-active" : "price-tab"}
                         id="individual" onClick={() => setPriceToggle(1)} > Pażdziernik-Maj </div>
                    <div className={priceToggle === 2 ? "price-tab price-active" : "price-tab"}
                         id="business" onClick={() => setPriceToggle(2)} > Czerwiec-Wrzesień </div>
                </div>
                <div className={priceToggle === 1 ? "prices-table" : "prices-table-disabled"}>
                    <div className="prices-row">
                        <div className="prices-item prices-highlight">
                            <div>1 dzień</div>
                            <div>100 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>9 dni</div>
                            <div>140 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>2 dni</div>
                            <div>100 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>10 dni</div>
                            <div>150 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>3 dni</div>
                            <div>100 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>11 dni</div>
                            <div>160 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>4 dni</div>
                            <div>100 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>12 dni</div>
                            <div>170 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>5 dni</div>
                            <div>110 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>13 dni</div>
                            <div>180 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>6 dni</div>
                            <div>120 PLN</div>
                        </div>
                        <div className="prices-item prices-highlight">
                            <div>14 dni</div>
                            <div>190 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item prices-highlight">
                            <div>7 dni</div>
                            <div>130 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>15 dni</div>
                            <div>200 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>8 dni</div>
                            <div>130 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>16 dni</div>
                            <div>210 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row-alt">
                        <div className="prices-item-alt">kolejna doba</div>
                        <div className="prices-item-alt">+10 PLN</div>
                    </div>
                </div>
                <div className={priceToggle === 2 ? "prices-table" : "prices-table-disabled"}>
                    <div className="prices-row">
                        <div className="prices-item prices-highlight">
                            <div>1 dzień</div>
                            <div>100 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>9 dni</div>
                            <div>210 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>2 dni</div>
                            <div>120 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>10 dni</div>
                            <div>220 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>3 dni</div>
                            <div>130 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>11 dni</div>
                            <div>230 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>4 dni</div>
                            <div>140 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>12 dni</div>
                            <div>240 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>5 dni</div>
                            <div>160 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>13 dni</div>
                            <div>250 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>6 dni</div>
                            <div>180 PLN</div>
                        </div>
                        <div className="prices-item prices-highlight">
                            <div>14 dni</div>
                            <div>260 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item prices-highlight">
                            <div>7 dni</div>
                            <div>200 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>15 dni</div>
                            <div>270 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row">
                        <div className="prices-item">
                            <div>8 dni</div>
                            <div>200 PLN</div>
                        </div>
                        <div className="prices-item">
                            <div>16 dni</div>
                            <div>280 PLN</div>
                        </div>
                    </div>
                    <div className="prices-row-alt">
                        <div className="prices-item-alt">kolejna doba</div>
                        <div className="prices-item-alt">+10 PLN</div>
                    </div>
                </div>
                <div className="prices-description">
                    <p className="prices-description-bold">WAŻNE Płatność tylko gotówką (nie przyjmujemy płatności
                        kartami)</p>
                    <p className="prices-description-regular">
                        Nr konta: 60 1050 1386 1000 0092 5509 5730. Odbiorca P.W. TRANSKAR Leszek Karcz, 41-940 Piekary
                        Śląskie. <br/>
                        W tytule proszę podać numer i nazwisko osoby na którą jest rezerwacja.</p>
                </div>
            </div>
        </div>
    );
}

export default Prices;
